var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "180px" },
              attrs: {
                placeholder: _vm.$t("statManager.roomType"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.typeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _vm.listQuery.type !== 3
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: {
                    click: function($event) {
                      return _vm.handlePropExport()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("statManager.export")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _vm.listQuery.type === 1
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("1000000-世界邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[16]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3962048649
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      prop: "date",
                      sortable: "custom",
                      align: "center",
                      width: "120px",
                      "class-name": _vm.getSortClass("date")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[0]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3296229822
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇总局数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[46]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      983425644
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇匹配-10000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[39]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1831739908
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇匹配-20000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[40]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2030179690
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇匹配-50000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[41]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3650840555
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇创建-10000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[42]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      526413672
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇创建-20000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[43]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2046828777
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇创建-50000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[44]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2487191662
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("蛇参与人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[45]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4107852527
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总局数"),
                      width: "80px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[1]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2991457375
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("胜利局数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[2]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      120760188
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("机器人局数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[4]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2383632186
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("机器人胜利数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[5]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2078859739
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总人次"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[3]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4011980829
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[47]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2503840749
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("匹配人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[48]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3563006306
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("邀请人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[49]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      888699875
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("排位人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[50]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3392685835
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("创建人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[51]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4252629706
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-4-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[17]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3557629768
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-6-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[18]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1543142791
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-4-8-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[19]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2403086662
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-6-5-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[20]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2175314348
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-8-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[21]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1485110573
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-4-4-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[22]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      319513646
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-6-4-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[23]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3824031407
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-4-8-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[24]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      807131048
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-6-5-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[25]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      116927273
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-4-4-创建"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[26]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3246297642
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("10000-其他"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[27]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2455848107
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-4-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[28]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3708140964
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-6-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[29]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3017937189
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-4-8-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[30]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3636794701
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-4-4-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[31]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      298913292
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-其他"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[32]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      14741199
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-4-4-排位"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[33]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4002431630
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-4-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[34]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2268611401
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-6-4-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[35]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3225697288
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-4-8-匹配"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[36]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2941525195
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-4-4-邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[37]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2634248330
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("50000-其他"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[38]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      874654021
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("20000-争霸赛"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[6]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3503129848
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("80000-创建房间"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[7]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3099383193
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("80000-世界邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[8]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1445260470
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("100000-创建房间"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[9]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1140488023
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("100000-世界邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[10]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      10316175
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("200000-创建房间"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[11]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      870260046
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("200000-世界邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[12]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1035264653
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("500000-创建房间"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[13]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      630845772
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("500000-世界邀请"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[14]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2937100171
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("1000000-创建房间"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[15]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3797044042
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.listQuery.type === 2
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-10000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[38]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      874654021
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      prop: "date",
                      sortable: "custom",
                      align: "center",
                      width: "120px",
                      "class-name": _vm.getSortClass("date")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[0]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3296229822
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总局数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[1]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2991457375
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总参与人数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[2]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      120760188
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总回收"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[39]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1831739908
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-100-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[3]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4011980829
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-100-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[4]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2383632186
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-100-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[5]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2078859739
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-100-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[6]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3503129848
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-1000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[7]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3099383193
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-1000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[8]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1445260470
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-1000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[9]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1140488023
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-1000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[10]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      10316175
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-10000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[11]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      870260046
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-10000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[12]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1035264653
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-10000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[13]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      630845772
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("房间-10000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[14]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2937100171
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-100-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[15]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3797044042
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-100-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[16]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3962048649
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-100-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[17]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3557629768
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-100-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[18]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1543142791
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-1000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[19]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2403086662
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-1000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[20]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2175314348
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-1000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[21]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1485110573
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-1000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[22]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      319513646
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-10000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[23]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3824031407
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-10000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[24]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      807131048
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-10000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[25]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      116927273
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("人数-10000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[26]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3246297642
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-100-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[27]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2455848107
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-100-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[28]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3708140964
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-100-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[29]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3017937189
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-100-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[30]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3636794701
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-1000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[31]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      298913292
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-1000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[32]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      14741199
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-1000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[33]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4002431630
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-1000-5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[34]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2268611401
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-10000-2"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[35]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3225697288
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-10000-3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[36]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2941525195
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("回收-10000-4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[37]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2634248330
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.listQuery.type === 3
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("教学"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[11]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      870260046
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      prop: "date",
                      sortable: "custom",
                      align: "center",
                      width: "120px",
                      "class-name": _vm.getSortClass("date")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[0]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3296229822
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("总入口"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[1]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2991457375
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("档位-100"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[2]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      120760188
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("档位-1000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[3]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4011980829
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("档位-10000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[4]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2383632186
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("开始匹配-100"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[5]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2078859739
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("开始匹配-1000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[6]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3503129848
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("开始匹配-10000"),
                      width: "130px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[7]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3099383193
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("推荐-100"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[8]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1445260470
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("推荐-1000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[9]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      1140488023
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("推荐-10000"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[10]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      10316175
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }